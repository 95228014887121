<template lang="pug">
    .container
      #logo
        svg.logo-2mundos(xmlns='http://www.w3.org/2000/svg', wdith='100%', height='100%', viewBox='0 0 1000 160', xml:space='preserve')
          circle.logo-2mundos-path(fill='#46A8C6', stroke-width='1', fill-opacity='1', stroke='#46A8C6', cx='971.7', cy='27.7', r='23.5')
          path.logo-2mundos-path(fill='#FFF', stroke-width='1', fill-opacity='1', stroke='#FFF', d='M892.5,127.9c-12.9,0-28.1-4.2-41.8-12.7l-15.9,26.8c3.2,2.1,6.5,4,9.9,5.7c25.4,1.8,50.3,3.8,74.6,6c14.5-6.1,22.7-17.9,22.7-34.4V119c0-23.3-17.3-31.1-46-38.2c-11.4-2.9-14-4.4-14-6.6v-0.4c0-2.3,2.3-3.5,6.9-3.5c9.6,0,24.2,3.9,36.2,10.6l15-28c-13.7-8.3-31.6-13.1-50.3-13.1c-29.5,0-48.8,13.9-48.8,38.8v0.4c0,23.5,16.7,31.1,45.4,38.2c11.4,2.9,12.6,4.4,12.6,6.6v0.4C899,126.9,897.1,127.9,892.5,127.9z')
          path.logo-2mundos-path(fill='#FFF', stroke-width='1', fill-opacity='1', stroke='#FFF', d='M812,145.5c14.7-11,24-28,24-46.4v-0.4c0-32-26.6-59-63.8-59c-37.4,0-64.2,27.4-64.2,59.4v0.4c0,15.9,6.6,30.5,17.7,41.2C755,142.1,783.9,143.7,812,145.5z M752,98.7c0-10.8,7.7-19.7,18.3-19.7c10.4,0,18.7,8.9,18.7,20.1v0.4c0,10.8-7.7,19.7-18.3,19.7c-10.4,0-18.7-8.9-18.7-20.1V98.7z')
          path.logo-2mundos-path(fill='#FFF', stroke-width='1', fill-opacity='1', stroke='#FFF', d='M702,139.7V5h-49v50.3c-5-8.1-16.1-15.6-33.3-15.6c-26.8,0-47.7,23.9-47.7,59.8v0.4c0,14.1,3.8,26.5,9.8,36.1C622.6,136.8,663,138,702,139.7z M617,98.9c0-11.4,8.2-20.3,19-20.3c10.8,0,19,9.1,19,20.3v0.4c0,11.4-8.2,20.3-19,20.3c-10.8,0-19-8.9-19-20.3V98.9z')
          path.logo-2mundos-path(fill='#FFF', stroke-width='1', fill-opacity='1', stroke='#FFF', d='M474.3,135c6.1,0,11.7,0,17.7,0V96.2c0-9.8,6.8-15.4,13.8-15.4c7.1,0,13.2,5.6,13.2,15.4v39c17,0.1,31,0.3,47,0.6V81c0-25.5-15.2-41.3-39.1-41.3c-16,0-26.9,9.1-34.9,18.5V43h-46v92.1C455,135,464.6,135,474.3,135z')
          path.logo-2mundos-path(fill='#FFF', stroke-width='1', fill-opacity='1', stroke='#FFF', d='M437,135.1V43h-48v59c0,9.8-6.8,15.4-13.8,15.4S362,111.8,362,102V43h-48v74.1c0,7.6,2.5,14.3,5,20C357.2,136.1,397,135.4,437,135.1z')
          path.logo-2mundos-path(fill='#FFF', stroke-width='1', fill-opacity='1', stroke='#FFF', d='M160,144V95.8c0-9.6,7-14.9,13.8-14.9c6.9,0,13.2,5.4,13.2,14.9v46.8c16-0.8,31-1.6,47-2.3V95.8c0-9.6,6-14.9,12.8-14.9c6.9,0,12.2,5.4,12.2,14.9v43.4c16-0.6,31-1.2,47-1.6V81c0-25.5-14.9-41.3-39.2-41.3c-17.4,0-28.8,7.9-38.4,18.7c-6.2-12-18.4-18.7-33.8-18.7c-15.6,0-26.6,8.5-34.6,17.9V43h-47v104C129,146,146,145,160,144z')
          path.logo-2mundos-path(fill='#FFF', stroke-width='1', fill-opacity='1', stroke='#FFF', d='M36.7,89.5c8.2-8.8,13-12,17.8-12c1.7,0,5.7,0.4,5.7,4.1c0,1.1,0.1,4.4-7.5,9.8L7,124.2v31.6c31-3,63-5.7,97-8.1V120H72l8.3-4.9c12.1-7.8,23.6-17.4,23.6-36.5v-0.3c0-22.5-19.6-38.8-46.1-38.8c-24.8,0-38.2,10-50.3,24.9l-2.5,2.9l29.1,24.8L36.7,89.5z')
      h2 Error 404
      p We can’t find this page.
</template>

<script>
export default {
  name: "Error404",
  components: {},
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #44494b;
  background-image: url("../../assets/images/fundo-espaco.png");
}
#logo {
  width: 234px;
  user-select: none;
}
h2 {
  opacity: 0.9;
  font-size: 24.8px;
  font-weight: 600;
  text-align: center;
  color: #fff;
}
p {
  width: 220px;
  font-size: 20.7px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}
</style>